<template>
    <div class="vote">
        <h5header :name="$t('vote.desc41')" :right="false" />
        <div class="vote-con">
            <div class="vote-top flex">
                <div class="vote-top-info">
                    <div class="vote-tips">
                        <p v-if="actStatus == 0">{{ $t('vote.desc2') }}</p>
                        <p class="actvite" v-else-if="actStatus == 1">{{ $t('vote.desc3') }}</p>
                        <p v-else>{{ $t('vote.desc4') }}</p>
                    </div>
                    <div class="vote-top-name">{{ $t('vote.desc5') }}</div>
                    <div class="vote-top-time">{{ $t('vote.desc6') }}{{ configInfo.startTime }} - {{ configInfo.endTime
                        }}（GST）</div>
                    <div class="vote-top-h5">
                        <div class="h5-count" v-html="$t('vote.desc42', { n1: userNum })"></div>
                        <div class="h5-title flex">
                            <img src="../../public/images/vote/line1.png" alt="" />
                            <div>{{ $t('vote.desc51') }}</div>
                            <img src="../../public/images/vote/line2.png" alt="" />
                        </div>
                        <div class="h5-desc">{{ $t('vote.desc52') }}</div>
                    </div>
                    <div class="vote-top-desc">{{ $t('vote.desc7') }}</div>
                </div>
                <div class="vote-top-list">
                    <div class="vote-title">{{ $t('vote.desc8') }} <span>{{ allNum || 0 }}</span></div>
                    <div class="vote-timetips flex" v-if="actStatus == 1">
                        <div>{{ $t('vote.desc9') }}</div>
                        <p v-if="configInfo.time != undefined"><van-count-down :time="configInfo.time"
                                @finish="onFinish">
                                <template #default="timeData">
                                    <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                    <span class="block" v-else>{{ timeData.days }}</span>
                                    <span class="colon">{{ $t('vote.desc10') }}</span>
                                    <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                    <span class="block" v-else>{{ timeData.hours }}</span>
                                    <span class="colon">{{ $t('vote.desc11') }}</span>
                                    <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                    <span class="block" v-else>{{ timeData.minutes }}</span>
                                    <span class="colon">{{ $t('vote.desc12') }}</span>
                                    <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                    <span class="block" v-else>{{ timeData.seconds }}</span>
                                    <span class="colon">{{ $t('vote.desc13') }}</span>
                                </template>
                            </van-count-down></p>
                    </div>
                    <div class="vote-timetips flex" v-if="actStatus == 0">
                        <div>{{ $t('vote.desc17') }}</div>
                        <p v-if="configInfo.time != undefined"><van-count-down :time="configInfo.time"
                                @finish="onFinish">
                                <template #default="timeData">
                                    <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                    <span class="block" v-else>{{ timeData.days }}</span>
                                    <span class="colon">{{ $t('vote.desc10') }}</span>
                                    <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                    <span class="block" v-else>{{ timeData.hours }}</span>
                                    <span class="colon">{{ $t('vote.desc11') }}</span>
                                    <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                    <span class="block" v-else>{{ timeData.minutes }}</span>
                                    <span class="colon">{{ $t('vote.desc12') }}</span>
                                    <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                    <span class="block" v-else>{{ timeData.seconds }}</span>
                                    <span class="colon">{{ $t('vote.desc13') }}</span>
                                </template>
                            </van-count-down></p>
                    </div>
                    <div class="vote-ticket">
                        <div class="ticket-table flex">
                            <div>{{ $t('vote.desc14') }}</div>
                            <div>{{ $t('vote.desc15') }}</div>
                            <div>{{ $t('vote.desc16') }}</div>

                        </div>
                        <div class="ticket-tbody">
                            <div class="tbody-list flex" v-for="item in list" :key="item.id">
                                <div>{{ item.name }}</div>
                                <div>{{ item.num }}</div>
                                <div>{{ common.getnum(item.ratio * 100) }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vote-showmore flex" @click="handlerMore">
               <span v-if="!ismore">{{ $t('assetbag.desc38') }}</span>
               <span v-else>{{ $t('assetbag.desc37') }}</span> <img src="../../public/images/vote/downicon.png" alt="" :class="{updown:ismore}" />
            </div>
            <div class="vote-intr" v-if="ismore">
                <div class="intr-name">{{ $t('vote.desc18') }}</div>
                <div class="intr-list flex">
                    <div class="intr-info">
                        <div>{{ $t('vote.desc19') }}</div>
                        <p>{{ $t('vote.desc20') }}</p>
                        <p>{{ $t('vote.desc21') }}</p>
                    </div>
                    <div class="intr-info">
                        <div>{{ $t('vote.desc22') }}</div>
                        <p>{{ $t('vote.desc23') }}</p>
                        <p>{{ $t('vote.desc24') }}</p>
                        <p>{{ $t('vote.desc241') }}</p>
                    </div>
                    <div class="intr-info">
                        <div>{{ $t('vote.desc25') }}</div>
                        <p>{{ $t('vote.desc26') }}</p>
                        <p>{{ $t('vote.desc27') }}</p>
                    </div>
                </div>
            </div>
            <div class="h5vote-top-list">
                <div class="vote-title">{{ $t('vote.desc8') }} <span>{{ allNum || 0 }}</span></div>
                <div class="vote-timetips flex" v-if="actStatus == 1">
                    <div>{{ $t('vote.desc9') }}</div>
                    <p v-if="configInfo.time != undefined"><van-count-down :time="configInfo.time" @finish="onFinish">
                            <template #default="timeData">
                                <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                <span class="block" v-else>{{ timeData.days }}</span>
                                <span class="colon">{{ $t('vote.desc10') }}</span>
                                <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                <span class="block" v-else>{{ timeData.hours }}</span>
                                <span class="colon">{{ $t('vote.desc11') }}</span>
                                <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                <span class="block" v-else>{{ timeData.minutes }}</span>
                                <span class="colon">{{ $t('vote.desc12') }}</span>
                                <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                <span class="block" v-else>{{ timeData.seconds }}</span>
                                <span class="colon">{{ $t('vote.desc13') }}</span>
                            </template>
                        </van-count-down></p>
                </div>
                <div class="vote-timetips flex" v-if="actStatus == 0">
                    <div>{{ $t('vote.desc17') }}</div>
                    <p v-if="configInfo.time != undefined"><van-count-down :time="configInfo.time" @finish="onFinish">
                            <template #default="timeData">
                                <span class="block" v-if="timeData.days < 10">0{{ timeData.days }}</span>
                                <span class="block" v-else>{{ timeData.days }}</span>
                                <span class="colon">{{ $t('vote.desc10') }}</span>
                                <span class="block" v-if="timeData.hours < 10">0{{ timeData.hours }}</span>
                                <span class="block" v-else>{{ timeData.hours }}</span>
                                <span class="colon">{{ $t('vote.desc11') }}</span>
                                <span class="block" v-if="timeData.minutes < 10">0{{ timeData.minutes }}</span>
                                <span class="block" v-else>{{ timeData.minutes }}</span>
                                <span class="colon">{{ $t('vote.desc12') }}</span>
                                <span class="block" v-if="timeData.seconds < 10">0{{ timeData.seconds }}</span>
                                <span class="block" v-else>{{ timeData.seconds }}</span>
                                <span class="colon">{{ $t('vote.desc13') }}</span>
                            </template>
                        </van-count-down></p>
                </div>
                <div class="vote-ticket">
                    <div class="ticket-table flex">
                        <div>{{ $t('vote.desc14') }}</div>
                        <div>{{ $t('vote.desc15') }}</div>
                        <div>{{ $t('vote.desc16') }}</div>

                    </div>
                    <div class="ticket-tbody">
                        <div class="tbody-list flex" v-for="item in list" :key="item.id">
                            <div>{{ item.name }}</div>
                            <div>{{ item.num }}</div>
                            <div>{{ common.getnum(item.ratio * 100) }}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vote-opear flex">
                <div class="opear-config">
                    <div class="cofing-title">{{ $t('vote.desc28') }}</div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc29') }}</div>
                        <p v-if="actStatus == 0">{{ $t('vote.desc2') }}</p>
                        <p class="actvite" v-else-if="actStatus == 1">{{ $t('vote.desc3') }}</p>
                        <p class="red" v-else>{{ $t('vote.desc4') }}</p>
                    </div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc30') }}</div>
                        <p>{{ configInfo.startTime }}（GST）</p>
                    </div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc31') }}</div>
                        <p>{{ configInfo.endTime }}（GST）</p>
                    </div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc32') }}</div>
                        <p>{{ $t('vote.desc35') }}</p>
                    </div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc33') }}</div>
                        <p>{{ $t('vote.desc37') }}</p>

                    </div>
                    <div class="cofing-info flex">
                        <div>{{ $t('vote.desc34') }}</div>
                        <p>{{ $t('vote.desc36') }}</p>
                    </div>
                    <div class="config-tips">{{ $t('vote.desc38') }}</div>
                </div>
                <div class="opear-poll">
                    <div class="poll-title flex">
                        <div>{{ $t('vote.desc39') }}</div>
                        <p class="flex" @click="handlerRecord">{{ $t('vote.desc40') }} <img
                                src="../../public/images/vote/icon.png" alt=""></p>
                    </div>
                    <div class="poll-list flex">
                        <div class="flexcenter" :class="{ pollact: userOptionId == item.id }" v-for="item in optionList"
                            :key="item.id" @click="handlerType(item.id)">{{ item.optionValue }}</div>
                    </div>
                    <div class="poll-btn">
                        <div class="btn-info" v-if="actStatus == 1 && userNum > 0" @click="handlerVote"
                            v-loading="loading">
                            {{
            $t('vote.desc41') }}</div>
                        <div class="btn-info nullact" v-else-if="actStatus == 1 && userNum == 0">{{ $t('vote.desc43') }}
                        </div>
                        <div class="btn-info nullact" v-else>{{ $t('vote.desc41') }}</div>
                        <div class="btn-tips" v-html="$t('vote.desc42', { n1: userNum })" v-if="actStatus == 1"></div>
                    </div>
                </div>
            </div>
            <div class="h5poll-btn">
                <div class="btn-info" v-if="actStatus == 1 && userNum > 0" @click="showTips = true" v-loading="loading">
                    {{
            $t('vote.desc41') }}</div>
                <div class="btn-info nullact" v-else-if="actStatus == 1 && userNum == 0">{{ $t('vote.desc43') }}</div>
                <div class="btn-info nullact" v-else>{{ $t('vote.desc41') }}</div>

            </div>
            <!-- 记录 -->
            <div class="h5-record flex" @click="handlerRecord">
                <img src="../../public/images/vote/icon1.png" alt="" />{{ $t('vote.desc40') }}
            </div>
        </div>
        <van-popup v-model:show="showRecord" round :close-on-click-overlay="false">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showRecord = false" />
                <div class="modal-title">{{ $t("vote.desc40") }}</div>
                <div class="modal-thead flex">
                    <div>{{ $t('vote.desc45') }}</div>
                    <div>{{ $t('vote.desc46') }}</div>
                    <div>{{ $t('vote.desc47') }}</div>
                    <div>{{ $t('vote.desc48') }}</div>
                </div>
                <div class="modal-tbody" v-loading="loading1">
                    <div class="tbody-list flex" v-for="item in recordList" :key="item.id">
                        <div>{{ $t('vote.desc49') }}</div>
                        <div>{{ item.createTime }}</div>
                        <div>{{ item.userOptionValue }}</div>
                        <div>{{ $t('vote.desc50') }}</div>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="" />
                    <span>{{ $t("record.desc13") }}</span>
                </div>
                <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
        </van-popup>
        <van-popup v-model:show="showTips" round :close-on-click-overlay="false">
            <div class="modal-con">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTips = false" />
                <div class="modal-title">{{ $t("vote.desc39") }}</div>
                <div class="poll-list flex">
                    <div class="flexcenter" :class="{ pollact: userOptionId == item.id }" v-for="item in optionList"
                        :key="item.id" @click="handlerType(item.id)">{{ item.optionValue }}</div>
                </div>
                <div class="poll-btn" @click="handlerVote" v-loading="loading">{{ $t('vote.desc41') }}</div>

            </div>

        </van-popup>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
export default {
    components: {
        h5header
    },
    data() {
        return {
            showTips: false,
            loading1: false,
            showRecord: false,
            actStatus: 0,
            configInfo: {},
            allNum: 0,
            list: [],
            userNum: 0,
            optionList: [],
            userOptionId: 1,
            loading: false,
            page: 1,
            recordList: [],
            totals: 0,
            showNull: false,
            ismore:true
        }
    },
    mounted() {
        this.getinit();
        if(localStorage.getItem('ismobile') == 1){
            this.ismore=false
        }
    },
    methods: {
        handlerMore() {
            this.ismore?this.ismore=false:this.ismore=true;
        },
        handlerVote() {
            if (this.loading) return;
            this.loading = true;
            let info = this.optionList.find(item => item.id == this.userOptionId)
            this.$post(this.URL.vote.submit, {
                userOptionId: this.userOptionId,
                optionType: 1,
                userOptionValue: info.optionValue
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    this.$message.success(this.$t('vote.desc44'));
                    this.getinit();
                    this.showTips = false;
                } else {

                    this.$message.error(res.message)
                }
            })
        },
        onFinish(val) {

        },
        handlerType(id) {
            this.userOptionId = id;
        },
        gettime() {
            return new Date(new Date().getTime() + (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000);
        },
        getinit() {
            this.$post(this.URL.vote.config, {}).then(res => {
                if (res.code == 0) {

                    this.configInfo = res.data;
                    let time = 4 * 60 * 60 * 1000, today = this.gettime(), start = new Date(this.configInfo.startTime), end = new Date(this.configInfo.endTime),
                        startTime = start.getTime() + time, endTime = end.getTime() + time, todayTime = today.getTime();
                    if (todayTime < startTime) {
                        this.actStatus = 0;
                        this.configInfo.time = startTime - todayTime;
                    } else if (todayTime > endTime) {
                        this.actStatus = 2;
                        this.configInfo.time = time;
                    } else {
                        this.actStatus = 1;
                        this.configInfo.time = endTime - todayTime;
                    }
                    this.userNum = this.configInfo.num;
                }
            })
            this.$post(this.URL.vote.statistics, {}).then(res => {
                if (res.code == 0) {
                    this.list = res.data.ratios;
                    this.allNum = res.data.allNum
                }
            })
            this.$post(this.URL.vote.option, {}).then(res => {
                if (res.code == 0) {
                    this.optionList = res.data;
                    this.userOptionId = this.optionList[0].id;
                }
            })
        },
        handlerCurrent(val) {
            this.page = val;
            this.loading1 = false;
            this.getrecord();
        },
        handlerRecord() {
            this.page = 1;
            this.getrecord();
            this.showRecord = true;
        },
        getrecord() {
            this.$post(this.URL.vote.record, {
                page: this.page,
                pageSize: 10
            }).then(res => {
                if (res.code == 0) {
                    this.recordList = res.data.list;
                    this.totals = res.data.totalCount;
                    if (this.recordList == 0) {
                        this.showNull = true
                    } else {
                        this.showNull = false;
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.vote {
    padding: 146px 0 96px;
    min-height: 100vh;
    background: url('../../public/images/vote/homebg.png') top no-repeat;
    background-size: 100% auto;
    background-color: #000;

    .h5-record {
        display: none;
    }

    .vote-con {
        width: 1240px;
        margin: 0 auto;

        .vote-top {
            justify-content: space-between;

            .vote-top-info {
                flex: 0 0 688px;

                .vote-tips {
                    height: 40px;

                    p {
                        width: max-content;
                        padding: 0 16px;
                        height: 40px;
                        border: 1px solid #767676;
                        box-sizing: border-box;
                        line-height: 38px;
                        font-size: 24px;
                        color: #767676;
                        text-align: center;
                        text-transform: capitalize;
                    }

                    .actvite {
                        border: 1px solid #C9FA5B;
                        color: #C9FA5B;
                    }
                }

                .vote-top-name {
                    margin: 24px 0;
                    font-size: 56px;
                    color: #fff;
                    line-height: 56px;
                }

                .vote-top-time {
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.8);
                    text-transform: capitalize;
                }

                .vote-top-h5 {
                    display: none;
                }

                .vote-top-desc {
                    margin-top: 24px;
                    font-size: 16px;
                    color: #fff;
                }
            }

            .vote-top-list {
                flex: 0 0 488px;
                padding: 24px;
                height: max-content;
                border: 1px solid rgba(255, 255, 255, 0.28);
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%);
                box-sizing: border-box;

                .vote-title {
                    font-size: 20px;
                    color: #FFFFFF;
                    font-weight: 600;
                    text-transform: capitalize;
                    span {
                        margin-left: 8px;
                        color: #C9FA5B;
                    }
                }

                .vote-timetips {
                    margin: 16px 0 0;
                    font-size: 16px;
                    color: #fff;
                    line-height: 20px;
                    text-transform: capitalize;
                    .colon,
                    .block {
                        font-size: 16px;
                        line-height: 20px;
                        color: #fff;
                        text-transform: capitalize;
                    }

                    .block {
                        padding: 0 5px;
                        color: #C9FA5B;
                    }
                }

                .vote-ticket {
                    margin-top: 16px;
                    background: #181818;

                    .ticket-table {
                        padding: 16px;
                        text-transform: capitalize;
                        div {
                            flex: 0 0 33.3%;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.6);
                            text-align: center;
                            line-height: 20px;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }

                    .tbody-list {
                        padding: 16px;
                        border-top: 1px solid rgba(255, 255, 255, 0.05);

                        div {
                            flex: 0 0 33.3%;
                            font-size: 16px;
                            color: #fff;
                            text-align: center;
                            line-height: 20px;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }

                    }
                }
            }
        }
        .vote-showmore{
            display: none;
        }
        .vote-intr {
            margin: 48px 0;
            padding: 24px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%);
            border: 1px solid rgba(255, 255, 255, 0.28);
            border-radius: 12px;

            .intr-name {
                font-size: 20px;
                color: #FFFFFF;
                font-weight: 600;
                text-transform: capitalize;
            }

            .intr-list {
                flex-wrap: wrap;

                .intr-info {
                    flex: 0 0 50%;
                    margin-top: 16px;
                    font-size: 14px;
                    color: #fff;

                    div {
                        color: #FFC120;
                        text-transform: capitalize;
                    }

                    p {
                        text-indent: 14px;
                    }

                    &:first-child {
                        padding-right: 24px;
                    }

                    &:nth-child(2) {
                        padding-left: 24px;
                    }

                    &:last-child {
                        flex: 0 0 100%;
                    }
                }
            }
        }

        .vote-opear {
            justify-content: space-between;

            .opear-config {
                flex: 0 0 608px;
                padding: 24px;
                background: #181818;
                border: 1px solid solid rgba(255, 255, 255, 0.28);
                border-radius: 12px;

                .cofing-title {
                    margin-bottom: 24px;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 600;
                    line-height: 34px;
                    text-transform: capitalize;
                }

                .cofing-info {
                    margin-bottom: 16px;
                    font-size: 16px;
                    color: #fff;
                    text-transform: capitalize;
                    div {
                        flex: 0 0 40%;
                    }

                    p {
                        flex: 0 0 60%;
                        text-align: right;
                    }

                    .actvite {
                        color: #C9FA5B;
                    }

                    .red {
                        color: #EB4343;
                    }
                }

                .config-tips {
                    padding-top: 8px;
                    font-size: 16px;
                    color: #FFC120;
                }
            }

            .opear-poll {
                position: relative;
                flex: 0 0 608px;
                background: #181818;
                padding: 24px;
                border-radius: 12px;

                .poll-title {
                    margin-bottom: 16px;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 600;
                    line-height: 34px;
                    justify-content: space-between;
                    text-transform: capitalize;

                    p {
                        font-size: 14px;
                        cursor: pointer;

                        img {
                            width: 34px;
                            height: 34px;
                        }
                    }
                }

                .poll-list {
                    flex-wrap: wrap;

                    div {
                        flex: 0 0 266px;
                        height: 56px;
                        margin-right: 12px;
                        background: #232323;
                        border: 1px solid #232323;
                        font-size: 16px;
                        color: #fff;
                        cursor: pointer;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        &:nth-child(-n + 2) {
                            margin-bottom: 12px;
                        }

                        &:hover {
                            background: rgba(201, 250, 91, 0.10);
                            border: 1px solid #C9FA5B;
                            color: #C9FA5B;
                        }
                    }

                    .pollact {
                        background: rgba(201, 250, 91, 0.10);
                        border: 1px solid #C9FA5B;
                        color: #C9FA5B;
                    }
                }

                .poll-btn {
                    position: absolute;
                    bottom: 24px;
                    width: 560px;
                    padding: 0;
                    text-transform: capitalize;
                    .btn-info {
                        width: 100%;
                        height: 64px;
                        background: url('../../public/images/vote/btnbg.png') center no-repeat;
                        background-size: 100% 100%;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;
                        text-align: center;
                        font-weight: 600;
                        line-height: 62px;
                    }

                    .nullact {
                        background: url('../../public/images/vote/btnbg1.png') center no-repeat;
                        background-size: 100% 100%;
                    }

                    .btn-tips {
                        margin-top: 8px;
                        font-size: 16px;
                        color: #fff;
                        text-align: center;
                        line-height: 20px;
                    }
                }
            }
        }

        .h5vote-top-list,
        .h5poll-btn {
            display: none;
        }
    }
}

.modal-con {
    position: relative;
    padding: 32px;
    width: 800px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        margin-bottom: 24px;
        font-size: 20px;
        color: #ffffff;
        text-transform: capitalize;
    }

    .modal-thead {
        padding: 20px;
        background: #232323;
        text-transform: capitalize;
        div {
            flex: 0 0 25%;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);
            text-align: center;

            &:last-child {
                text-align: right;
            }

            &:first-child {
                text-align: left;
            }
        }
    }

    .modal-tbody {
        min-height: 100px;
    }

    .tbody-list {
        padding: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        div {
            flex: 0 0 25%;
            font-size: 13px;
            color: #fff;
            text-align: center;

            &:last-child {
                text-align: right;
            }

            &:first-child {
                text-align: left;
            }
        }
    }
}

.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}

.planet-page {
    margin-top: 24px;
    padding: 0;

    ::v-deep .el-pagination {
        button {
            background-color: rgba(255, 255, 255, 0);
            color: #666666;
            font-size: 14px;
        }

        .el-pager {
            li {
                background: rgba(255, 255, 255, 0);
                font-size: 14px;
                color: #666666;
            }

            .is-active {
                background: #c9fa5b !important;
                border-radius: 4px !important;
                color: #000000 !important;
            }
        }
    }
}

@media (max-width: 1240px) {
    .vote {
        padding: 88px 16px 70px;

        .vote-con {
            width: 100%;

            .vote-top {
                .vote-top-info {
                    flex: 0 0 48.5%;

                    .vote-tips {
                        p {
                            height: 32px;
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }

                    .vote-top-name {
                        margin: 16px 0;
                        font-size: 24px;
                        line-height: 26px;
                    }

                    .vote-top-time {
                        font-size: 14px;
                    }

                    .vote-top-desc {
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }

                .vote-top-list {
                    flex: 0 0 48.5%;
                    padding: 12px;

                    .vote-title {
                        font-size: 16px;
                    }

                    .vote-timetips {
                        margin-top: 12px;
                        font-size: 14px;

                        .block,
                        .colon {
                            font-size: 14px;
                        }
                    }

                    .vote-ticket {
                        margin-top: 12px;

                        .ticket-table {
                            padding: 12px;

                            div {
                                font-size: 14px;
                            }
                        }

                        .tbody-list {
                            padding: 12px;

                            div {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .vote-intr {
                margin: 24px 0;
            }

            .vote-opear {
                .opear-config {
                    flex: 0 0 48.5%;
                    padding: 12px;

                    .cofing-title {
                        margin-bottom: 12px;
                        font-size: 16px;
                    }

                    .cofing-info {
                        font-size: 14px;
                        margin-bottom: 12px;
                    }

                    .config-tips {
                        font-size: 14px;
                    }
                }

                .opear-poll {
                    flex: 0 0 48.5%;
                    padding: 12px;

                    .poll-title {
                        font-size: 16px;
                        line-height: 24px;

                        p {
                            font-size: 14px;

                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    .poll-list {
                        div {
                            flex: 0 0 48.5%;
                            height: 44px;
                            margin-right: 3%;
                            line-height: 42px;
                            font-size: 14px;
                        }
                    }

                    .poll-btn {
                        width: 100%;
                        left: 0;
                        bottom: 12px;
                        padding: 0 12px;

                        .btn-info {
                            height: 48px;
                            line-height: 48px;
                            font-size: 14px;
                        }

                        .btn-tips {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .modal-con {
        width: calc(100vw - 8px);
        padding: 16px;
    }
}

@media (max-width: 767px) {
    .vote {
        padding: 68px 16px 100px;
        background: url('../../public/images/vote/h5bg.png') top no-repeat;
        background-size: 100% auto;

        .h5-record {
            display: flex;
            position: fixed;
            top: 68px;
            right: 0;
            padding: 0 12px;
            width: max-content;
            height: 28px;
            background: #C9FA5B;
            border-radius: 4px 0 0 4px;
            font-size: 12px;
            color: #000000;
            line-height: 28px;
            text-transform: capitalize;
            img {
                width: 16px;
                height: 16px;
                margin: 6px 4px 0 0;
            }
        }

        .vote-con {
            .vote-top {
                .vote-top-info {
                    flex: 0 0 100%;

                    .vote-tips {
                        height: 20px;

                        p {
                            margin: 0 auto;
                            height: 20px;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }

                    .vote-top-name {
                        font-size: 24px;
                        line-height: 28px;
                        text-align: center;
                    }

                    .vote-top-time {
                        font-size: 12px;
                        text-align: center;
                    }

                    .vote-top-h5 {
                        display: block;

                        .h5-count {
                            margin: 16px 0 32px;
                            font-size: 16px;
                            color: #fff;
                            line-height: 20px;
                            text-align: center;
                            text-transform: capitalize;
                        }

                        .h5-title {
                            justify-content: center;
                            text-transform: capitalize;
                            div {
                                padding: 0 12px;
                                font-size: 16px;
                                color: #C9FA5B;
                                line-height: 20px;
                            }

                            img {
                                width: 95px;
                                height: 17px;
                                margin-top: 1.5px;
                            }
                        }

                        .h5-desc {
                            margin-top: 16px;
                            font-size: 14px;
                            color: #FFFFFF;
                            
                        }
                    }

                    .vote-top-desc {
                        font-size: 12px;
                        
                    }
                }

                .vote-top-list {
                    display: none;
                }
            }   
            .vote-showmore{
                margin-top: 16px;
                display: flex;
                justify-content: center;
                font-size:12px;
                color: rgba(255, 255, 255, 0.7);
                line-height: 24px;         
                img{
                    width: 24px;
                    height: 24px;
                    transition: .5s;
                }
                .updown{
                transform: rotate(-180deg);
                transition: .5s;
                }
            }

            .vote-intr {
                padding: 0;
                background: none;
                border: 0;
                margin: 16px 0 0;

                .intr-name {
                    font-size: 14px;
                }

                .intr-list {
                    .intr-info {
                        flex: 0 0 100%;
                        font-size: 12px;

                        &:first-child {
                            padding-right: 0;
                        }

                        &:nth-child(2) {
                            padding-left: 0;
                        }
                    }
                }
            }

            .vote-opear {
                margin-top: 32px;

                .opear-config {
                    flex: 0 0 100%;
                    padding: 16px;

                    .cofing-title {
                        margin-bottom: 24px;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .cofing-info {
                        font-size: 12px;
                    }

                    .config-tips {
                        font-size: 12px;
                    }
                }

                .opear-poll {
                    display: none;
                }
            }

            .h5vote-top-list {
                display: block;
                margin-top: 32px;
                padding: 16px;
                background: #181818;

                .vote-title {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-weight: 600;
                    text-transform: capitalize;
                    span {
                        margin-left: 8px;
                        color: #C9FA5B;
                    }
                }

                .vote-timetips {
                    margin: 14px 0 0;
                    font-size: 12px;
                    color: #fff;
                    line-height: 22px;
                    text-transform: capitalize;
                    .colon,
                    .block {
                        font-size: 12px;
                        color: #fff;
                        text-transform: capitalize;
                    }

                    .block {
                        padding: 0 5px;
                        color: #C9FA5B;
                    }
                }

                .vote-ticket {
                    margin-top: 14px;

                    .ticket-table {
                        padding: 12px;
                        background: #232323;

                        div {
                            flex: 0 0 33.3%;
                            font-size: 12px;
                            color: rgba(255, 255, 255, 0.6);
                            text-align: center;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }

                    .tbody-list {
                        padding: 12px;
                        border-top: 1px solid rgba(255, 255, 255, 0.05);

                        div {
                            flex: 0 0 33.3%;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }

                    }
                }
            }

            .h5poll-btn {
                position: fixed;
                bottom: .24rem;
                left: 0;
                width: 100%;
                padding: 0 16px;
                display: block;
                z-index: 56;
                background: #000;
                .btn-info {
                    width: 100%;
                    height: 50px;
                    background: url('../../public/images/vote/btnbg2.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: #000000;
                    cursor: pointer;
                    text-align: center;
                    font-weight: 600;
                    line-height: 50px;
                }

                .nullact {
                    background: url('../../public/images/vote/btnbg3.png') center no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .modal-con {
        padding: 16px;

        .close {
            top: 16px;
            right: 16px;
        }

        .modal-title {
            font-size: 16px;
            line-height: 24px;

        }

        .poll-list {
            flex-wrap: wrap;

            div {
                flex: 0 0 48.5%;
                height: 42px;
                margin-right: 3%;
                background: #232323;
                border: 1px solid #232323;
                font-size: 12px;
                color: #fff;
                cursor: pointer;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &:nth-child(-n + 2) {
                    margin-bottom: 3%;
                }

            }

            .pollact {
                background: rgba(201, 250, 91, 0.10);
                border: 1px solid #C9FA5B;
                color: #C9FA5B;
            }
        }

        .poll-btn {
            margin-top: 24px;
            width: 100%;
            height: 50px;
            background: url('../../public/images/vote/btnbg4.png') center no-repeat;
            background-size: 100% 100%;
            font-size: 16px;
            color: #000000;
            cursor: pointer;
            text-align: center;
            font-weight: 600;
            line-height: 50px;
        }

        .modal-thead {
            padding: 12px;

            div {
                font-size: 12px;
            }
        }

        .tbody-list {
            padding: 12px;

            div {
                font-size: 12px;
                padding-right: 3px;

                &:last-child {
                    padding-right: 0;
                }
            }

        }
    }
}
</style>